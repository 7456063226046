import { Locale, type LocalizedString } from "./Locale.js";

export enum Category {
	Racks = "Racks",
	Robots = "Robots",
	Cranes = "Cranes",
	VertinodeAi = "VertinodeAi",
}

export const categoryNameMap: Record<Category, LocalizedString> = {
	[Category.Racks]: {
		[Locale.cs]: "Věže",
		[Locale.en]: "Racks",
		[Locale.pl]: "Wieże",
		[Locale.sk]: "Veže",
	},
	[Category.Robots]: {
		[Locale.cs]: "Roboti",
		[Locale.en]: "Robots",
		[Locale.pl]: "Roboty",
		[Locale.sk]: "Roboti",
	},
	[Category.Cranes]: {
		[Locale.cs]: "Zakladače",
		[Locale.en]: "Cranes",
		[Locale.pl]: "Żurawie",
		[Locale.sk]: "Zakladače",
	},
	[Category.VertinodeAi]: {
		[Locale.cs]: "VertiNode AI",
		[Locale.en]: "VertiNode AI",
		[Locale.pl]: "VertiNode AI",
		[Locale.sk]: "VertiNode AI",
	},
};

export const categorySlugMap: Record<Category, LocalizedString> = {
	[Category.Racks]: {
		[Locale.cs]: "veze",
		[Locale.en]: "racks",
		[Locale.pl]: "wieze",
		[Locale.sk]: "veze",
	},
	[Category.Robots]: {
		[Locale.cs]: "roboti",
		[Locale.en]: "robots",
		[Locale.pl]: "roboty",
		[Locale.sk]: "roboti",
	},
	[Category.Cranes]: {
		[Locale.cs]: "zakladace",
		[Locale.en]: "cranes",
		[Locale.pl]: "zurawie",
		[Locale.sk]: "zakladace",
	},
	[Category.VertinodeAi]: {
		[Locale.cs]: "vertinode-ai",
		[Locale.en]: "vertinode-ai",
		[Locale.pl]: "vertinode-ai",
		[Locale.sk]: "vertinode-ai",
	},
};

export const categoryTextMap: Record<Category, LocalizedString> = {
	[Category.Racks]: {
		[Locale.cs]:
			"Vertikální zakladače (také označované jako věže nebo výtahové skladovací systémy) skladují zboží ve velké výšce bezpečně a zcela pod kontrolou. Tato technologie zajišťuje optimální využití prostoru, umožňuje úspory 70 až 90 % plochy v porovnání se standardním policovým nebo paletovým regálem a výrazné zrychlení toku materiálu. Naše věžové skladovací systémy, dodávané na klíč potřebám zákazníků, přinášejí viditelný efekt okamžitě po uvedení do provozu ve skladu. Automatický výtahový systém je jednoduchý, jeho obsluha je pohodlná a nevyžaduje časově náročnou údržbu. Umožňuje snížit pracovní náklady, udržuje efektivní přehled o položkách na skladě a přitom zabírá mnohem méně plochy než tradiční řešení. Při správné integraci dokáží vertikální zakladače výrazně zrychlit tok materiálu a jsou tak ideálním modulárním řešením například pro e-commerce. Návratnost investice do věží VertiFlex je rychlejší, než by se dalo očekávat. Většina projektů počítá s návratností 12 – 24 měsíců.",
		[Locale.en]:
			"Vertical storage lifts (also referred to as towers or elevator storage systems) store goods at great heights safely and completely under control. This technology ensures optimum use of space, saving 70% to 90% of the area compared to standard shelving or pallet racking and significantly speeding up material flow. Our tower storage systems, delivered turnkey to customer needs, deliver a visible effect immediately after commissioning in the warehouse. The automatic lift system is simple, convenient to operate and requires no time-consuming maintenance. It allows you to reduce labour costs, keep an efficient overview of items in stock, while taking up much less floor space than traditional solutions. When properly integrated, vertical storage lifts can significantly speed up material flow, making them an ideal modular solution for e-commerce, for example. The return on investment for VertiFlex towers is faster than one would expect. Most projects expect a payback period of 12 to 24 months.",
		[Locale.pl]:
			"Pionowe systemy magazynowe (nazywane również wieżami lub systemami magazynowania windowego) to nowoczesne rozwiązania logistyczne, które umożliwiają bezpieczne i kontrolowane przechowywanie artykułów w układzie pionowym. Dzięki zaawansowanej technologii nasze systemy zostały zaprojektowane z myślą o maksymalnym wykorzystaniu dostępnej przestrzeni magazynowej, co pozwala zaoszczędzić od 70% do 90% powierzchni w porównaniu z tradycyjnymi regałami półkowymi i paletowymi." +
			"Nasze pionowe windy magazynowe, w pełni dostosowane do indywidualnych potrzeb klientów, zapewniają widoczne korzyści już od momentu uruchomienia w magazynie.\n" +
			"Automatyczne pionowe systemy magazynowe wyróżniają się prostotą obsługi, minimalnym zapotrzebowaniem na konserwację oraz znacznymi oszczędnościami przestrzeni magazynowej. Ich zastosowanie pozwala na przyspieszenie przepływu materiałów, obniżenie kosztów pracy oraz utrzymanie porządku w magazynie, co czyni je doskonałym wyborem, zwłaszcza dla branży e-commerce.\n" +
			"Zwrot z inwestycji w pionowe windy magazynowe VertiFlex następuje szybciej, niż można się spodziewać – często już w ciągu roku lub dwóch od wdrożenia.",
		[Locale.sk]: "TODO", // TODO SK LANG
	},
	[Category.Robots]: {
		[Locale.cs]:
			"Naše řešení využívající robotů Geek+ představuje špičkovou technologickou platformu zaměřenou na inteligentní robotická řešení, která transformují skladové operace. Tato pokročilá technologie využívá moderní roboty navržené pro různé činnosti, včetně vychystávání, třídění, dopravy a skladování zboží.",
		[Locale.en]:
			"Our Geek+ robot solution is a cutting-edge technology platform focused on intelligent robotic solutions that transform warehouse operations. This advanced technology uses state-of-the-art robots designed for a variety of activities including picking, sorting, transporting and storing goods.",
		[Locale.pl]:
			"Nasze rozwiązanie wykorzystujące roboty Geek+ to zaawansowana platforma technologiczna skupiająca się na inteligentnych rozwiązaniach robotycznych, które transformują operacje magazynowe. Ta nowoczesna technologia wykorzystuje roboty zaprojektowane do różnych działań, w tym kompletowania, sortowania, transportu i magazynowania towarów.",
		[Locale.sk]: "TODO", // TODO SK LANG
	},
	[Category.Cranes]: {
		[Locale.cs]:
			"Plně automatizované systémy zvyšují kvalitu, flexibilitu a efektivitu skladu. Nabízíme širokou škálu technologií pro automatizované skladování a vychystávání palet a menšího zboží, jako jsou kartony nebo přepravky.",
		[Locale.en]:
			"Fully automated systems increase the quality, flexibility and efficiency of the warehouse. We offer a wide range of technologies for automated storage and picking of pallets and smaller goods such as cartons or crates.",
		[Locale.pl]:
			"Pełni zautomatyzowane systemy zwiększają jakość, elastyczność i efektywność magazynów. Oferujemy szeroką gamę technologii do zautomatyzowanego składowania i kompletowania palet oraz mniejszych towarów, takich jak kartony czy pojemniki.",
		[Locale.sk]: "TODO", // TODO SK LANG
	},
	[Category.VertinodeAi]: {
		[Locale.cs]:
			"Software pro automatizovanou logistiku s nejnovější technologií umělé inteligence a kompatibilní s většinou skladovacích systémů. VertiNode Ai má mnoho použití, ale vynikne především v prostředí e-commerce. Komplexní řešení dovolí integrovat výstupy z AI přímo do procesů společnosti. ERP systém se může od VertiNode Ai dozvědět okamžitě po předání zakázky k vyskladnění cílovou vyskladňovací pozici a očekávaný čas zpracování, tyto informace lze využít pro okamžitou komunikaci klientovi v procesu zpracování objednávky. Stavy zakázky jsou přístupné přes API rozhraní. ERP systém (a potažmo klient) tak může kdykoliv zjistit, kdy přibližně dojde k expedici, že expedice byla započata a úspěšně dokončena nebo zdržena, tracking ID zakázky pro okamžitou konfirmaci přepravní společnosti, to vše za účelem zvýšení transparentnosti a rychlosti dodávky.",
		[Locale.en]:
			"Software for automated logistics with the latest artificial intelligence technology and compatible with most storage systems. VertiNode Ai has many uses, but excels especially in the e-commerce environment. The comprehensive solution allows the integration of AI outputs directly into the company's processes. The ERP system can learn from VertiNode Ai immediately after the order is handed over for picking to the target picking position and the expected processing time, this information can be used for instant communication with the client in the order processing process. Order statuses are accessible via the API interface. The ERP system (and consequently the client) can find out at any time when the shipment will be dispatched, that the shipment has started and successfully completed or delayed, the tracking ID of the order for immediate confirmation by the carrier, all to increase transparency and delivery speed.",
		[Locale.pl]:
			"Oprogramowanie do zautomatyzowanej logistyki z najnowszą technologią sztucznej inteligencji, kompatybilne z większością systemów magazynowych. VertiNode Ai ma wiele zastosowań, ale wyróżnia się przede wszystkim w środowisku e-commerce. Kompleksowe rozwiązanie umożliwia integrację wyników AI bezpośrednio z procesami firmy. System ERP może natychmiast po przekazaniu zlecenia do wydania dowiedzieć się od VertiNode Ai o docelowej pozycji wydania i oczekiwanym czasie przetwarzania. Te informacje mogą być wykorzystane do bieżącej komunikacji z klientem w trakcie realizacji zamówienia. Statusy zamówienia są dostępne poprzez interfejs API. System ERP (a tym samym klient) może w każdej chwili sprawdzić, kiedy mniej więcej dojdzie do wysyłki, czy wysyłka została rozpoczęta, pomyślnie zakończona lub opóźniona, a także uzyskać numer śledzenia zamówienia do natychmiastowej potwierdzenia przez firmę transportową, wszystko to w celu zwiększenia transparentności i szybkości dostawy.",
		[Locale.sk]: "TODO", // TODO SK LANG
	},
};

export const categoryAboutMap: Record<Category, LocalizedString> = {
	[Category.Racks]: {
		[Locale.cs]: "Více o věžích",
		[Locale.en]: "More about racks",
		[Locale.pl]: "Więcej o wieżach",
		[Locale.sk]: "Viac o vežiach",
	},
	[Category.Robots]: {
		[Locale.cs]: "Více o robotech",
		[Locale.en]: "More about robots",
		[Locale.pl]: "Więcej o robotach",
		[Locale.sk]: "Viac o robotoch",
	},
	[Category.Cranes]: {
		[Locale.cs]: "Více o zakladačích",
		[Locale.en]: "More about cranes",
		[Locale.pl]: "Więcej o żurawiach",
		[Locale.sk]: "Viac o zakladačoch",
	},
	[Category.VertinodeAi]: {
		[Locale.cs]: "Více o Vertinode AI",
		[Locale.en]: "More about Vertinode AI",
		[Locale.pl]: "Więcej o Vertinode AI",
		[Locale.sk]: "Viac o Vertinode AI",
	},
};
